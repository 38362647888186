<template>
  <div class="task-list-page">
    <HeaderTabs
      :tabs="tabs"
      :value="taskStatus"
      @click="handleTabClick"
    >
      <div class="icon-wrap">
        <img class="add-icon" :src="AddIcon" alt="" @click="$router.push({name: 'TaskPublishTask'})" />
      </div>
    </HeaderTabs>

    <div class="card-list">
      <Card class="card" @click="handleCardClick" v-for="(item, index) in list" :key="index" :info="item" :config="config" />
    </div>
    <infinite-loading ref="infinite" @infinite="infiniteHandler">
      <span slot="no-more">
        <!-- 暂无更多 -->
      </span>
    </infinite-loading>
  </div>
</template>
<script src="../../utils/user.js"></script>
<script>
import HeaderTabs from '@/components/header/headerTabs/index.vue'
import AddIcon from '@/assets/images/common/add.png'
import Card from './components/listCard.vue'
import InfiniteLoading from 'vue-infinite-loading'
import { fetchGetTaskList, fetchGiveTaskList } from '@/api/task'
import { getUserName } from "@/utils/user"
export default {
  components: {
    HeaderTabs,
    Card,
    InfiniteLoading,
  },
  data() {
    return {
      taskType: '', // execute 我执行的任务，publish 我发布的任务
      taskStatus: '',
      AddIcon,
      tabs: [{ title: "未完成" }, { title: "已完成" }],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      list: [],
      config: [
        {label: '工作名称', field: 'taskName', type: 'one'},
        {label: '发起人员', field: 'createByName', type: 'one'},
        {label: '执行人员', field: 'taskReceiveName', type: 'one'},
        {label: '下达时间', field: 'createTime', type: 'one'}
      ]
    }
  },
  beforeRouteEnter(to, from, next) {
    if (to.name === 'TaskListStatus' && from.name !== 'TaskDetail') { 
      window.__taskListStatus_refresh__ = true;
    } else {
      window.__taskListStatus_refresh__ = false;
    }
    next();
  },
  activated() {
    if (window.__taskListStatus_refresh__) {
      this.taskType = this.$route.query.type === "1" ? 'execute' : 'publish';
      const taskStatus = this.$route.query.status === '1' ? '已完成' : '未完成';
      this.handleTabClick(taskStatus);
    }
  },
  methods: {
    /**
     * 跳转任务详情
     */
    handleCardClick(info) {
      this.$router.push(`/task/detail/${info.id}?type=${this.taskType}`);
    },

    /**
     * 重置列表
     */
    resetList() {
      this.$refs.infinite._events["$InfiniteLoading:reset"][0]();
      this.list = [];
      this.pageNo = 1;
      this.total = 0;
    },

    /**
     * tab切换
     */
    handleTabClick(tab) {
      this.taskStatus = tab;
      this.resetList()
    },

    /**
     * 上拉刷新
     */
    async infiniteHandler($state) {
      await this.fetchList();
      $state.loaded();
      this.pageNo += 1;
      if (this.total - this.list.length === 0) {
        $state.complete();
      }
    },

    /**
     * 获取列表
     */
    async fetchList() {
      let response = [];
      let data = {
        "taskSource": "0,1",
        "page.pageSize": this.pageSize,
        "taskState": this.taskStatus === '已完成' ? '1' : '0',
        "taskReceiveType": "0",
        "page.pageNo": this.pageNo
      }
      if (this.taskType === 'publish') {
        data.createBy = getUserName();
      }else if(this.activeName === 'execute') {
        data.taskReceive = getUserName();
      }

      if (this.taskType === 'publish') {
        response = await fetchGiveTaskList(data)
      }
      else if(this.taskType === 'execute') {
        response = await fetchGetTaskList(data)
      }
      this.list = this.list.concat(response.data || []);
      this.total = (response.page && response.page.total) || 0;
    }
  }
}
</script>

<style lang="scss" scoped>
.task-list-page {
  width: 100%;
  height: 100vh;
  background: #FAFBFC;
  .icon-wrap {
    width: 15px;
    height: 15px;
    position: absolute;
    top: 15px;
    right: 24px;
    display: flex;
  }
  .add-icon {
    width: 100%;
    height: 100%;
  }
  .card-list {
    padding-top: 12px;
    .card:not(:last-child) {
      margin-bottom: 6px;
    }
  }
}
</style>